.float-ui {
  @apply z-50 translate-x-0 rounded-md bg-gray-900 p-3 text-sm text-white shadow #{!important};
}

.float-ui-arrow {
  @apply bg-gray-900 #{!important};
}

.float-ui[data-float-ui-placement^="bottom"],
.float-ui[data-float-ui-placement^="top"] {
  @apply left-0 #{!important};
}

.float-ui[data-float-ui-placement^="bottom"] > .float-ui-arrow,
.float-ui[data-float-ui-placement^="top"] .float-ui-arrow {
  @apply absolute left-[6px] #{!important};
}
