.ng-select {
  @apply h-11 focus-visible:outline-none #{!important};
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply ring-primary bg-white shadow-none ring-2 #{!important};
}
.ng-select .ng-select-container {
  @apply flex items-center overflow-visible hover:cursor-pointer hover:bg-gray-50 hover:shadow-none #{!important};
  &[disabled] {
    @apply cursor-default #{!important};
  }
}
.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  @apply h-full border-gray-300 #{!important};
}
.ng-select .ng-value-label {
  @apply mr-2 #{!important};
}
.ng-select.ng-select-opened > .ng-select-container {
  @apply border-gray-300 #{!important};
}
.ng-select-error {
  .ng-select-container,
  &.ng-select-single .ng-select-container {
    @apply border-red-700 #{!important};
  }
}
.ng-select.auto-grow {
  @apply flex #{!important};

  .ng-select-container {
    @apply min-w-[100px] #{!important};
  }

  .ng-dropdown-panel {
    @apply w-auto #{!important};
  }
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-primary-shade-50 font-normal #{!important};
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  @apply bg-primary-shade-700 text-white #{!important};
}
.ng-select.ng-select-filtered .ng-placeholder {
  @apply hidden #{!important};
}
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBfbmdjb250ZW50LWRvdC1jMTAzPSIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHlsZT0iaGVpZ2h0OiAxNnB4OyBzdHJva2Utd2lkdGg6IDJweDsgd2lkdGg6IDE2cHg7Ij48cGF0aCBfbmdjb250ZW50LWRvdC1jMTAzPSIiIGQ9Ik0xOSA5TDEyIDE2TDUgOSIgc3Ryb2tlPSIjMzc0MTUxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD48L3N2Zz4=) !important;
  background-size: 16px !important;
  @media screen and (min-width: 672px) {
    background-size: 20px !important;
  }
  @apply top-0 block h-3 w-3 border-0 border-transparent bg-center bg-no-repeat #{!important};
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  @apply top-0 #{!important};
}
.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
.ng-select.ng-select-opened.ng-select-top > .ng-select-container,
.ng-dropdown-panel .ng-dropdown-panel-items {
  @apply rounded-md #{!important};
}
.ng-select .ng-dropdown-panel.ng-select-bottom {
  @apply mt-[5px] rounded-md border-none shadow-md #{!important};
}
.ng-select .ng-dropdown-panel.ng-select-top {
  @apply mb-[5px] rounded-md border-none shadow-md #{!important};
}
.ng-select.ng-select-opened .ng-select-container .ng-arrow-wrapper .ng-arrow {
  @apply rotate-180 #{!important};
}
.ng-select.button-secondary {
  @apply p-0 #{!important};
  &[disabled] {
    @apply opacity-50 #{!important};
  }
  .ng-select-container {
    @apply h-auto min-h-fit cursor-pointer border-none #{!important};
    @extend .button-secondary;
    .ng-value-container {
      @apply p-0 #{!important};
      .ng-placeholder {
        @apply text-primary-shade-700 #{!important};
      }
      .ng-input {
        @apply p-0 #{!important};
      }
    }
  }
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  @apply text-gray-400 #{!important};
}
.ng-select.ng-select-no-border .ng-select-container {
  @apply border-transparent #{!important};
}
