@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter.ttf") format("ttf-variations"), url("/assets/fonts/Inter.woff2") format("woff2-variations"),
    url("/assets/fonts/Inter.ttf") format("ttf"), url("/assets/fonts/Inter.woff2") format("woff2");
  font-weight: 100 600;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-ExtraBold.woff2") format("woff2");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-Black.woff2") format("woff2");
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-Black.woff2") format("woff2");
  font-weight: 900;
}

@font-face {
  font-display: block;
  font-family: "PaulSignature";
  src: url("/assets/fonts/PaulSignature.ttf") format("ttf-variations"), url("/assets/fonts/PaulSignature.woff2") format("woff2-variations"),
    url("/assets/fonts/PaulSignature.ttf") format("ttf"), url("/assets/fonts/PaulSignature.woff2") format("woff2");
  font-weight: 400;
}
