.btn-primary {
  @include button-default();
  @apply text-primary-contrast bg-primary border-primary active:bg-primary-shade-700 hover:bg-primary-shade-600 focus:bg-primary-shade-600;
}

.btn-primary[disabled] {
  @include disabled();
}

.btn-secondary {
  @include button-default();
  @apply text-primary border-solid border-gray-300 bg-white hover:bg-gray-50 focus:bg-gray-50 active:bg-gray-50;
}

.btn-secondary[disabled] {
  @include disabled();
}

.btn-text {
  @include button-default();
  @apply text-primary border-transparent bg-transparent text-base shadow-none;
}

.btn-sm {
  @apply px-3 py-2 text-sm;
}

.btn-lg {
  @apply px-5 py-4 text-lg;
}

.btn-xl {
  @apply px-6 py-5 text-lg leading-8; // Use the same text size as btn-lg but with a larger line height
}

.btn-rounded {
  @apply rounded-full p-1;
}

.btn-rounded-lg {
  @apply rounded-full p-2;
}

.btn-nav {
  @extend .btn-rounded, .btn-secondary;
  @apply text-default border-none text-lg;
}

.btn-group {
  @apply flex flex-nowrap items-center;
  button {
    @apply mx-0 min-w-[50px] rounded-none border-l-0;
  }
  button.btn-primary {
    @apply border-primary-shade-300 border-solid;
  }
  button:first-child {
    @apply rounded-l-lg rounded-r-none border-l border-solid;
  }

  button:last-child {
    @apply rounded-l-none rounded-r-lg;
  }
}

.btn-toggle-group {
  @apply flex flex-nowrap items-center;
  button {
    @apply mx-0 min-w-[50px] rounded-none border-l-0 border-solid border-gray-400;
  }
  button:first-child {
    @apply rounded-l-lg rounded-r-none border-l border-solid border-gray-400;
  }
  button:last-child {
    @apply rounded-l-none rounded-r-lg;
  }
}

// Ensure that the browser doesnt apply any default styles to the anchor tags when they behave like buttons
a.btn-primary,
a.btn-secondary {
  @apply no-underline;
}

a.btn-primary {
  @apply text-primary-contrast;
  &:visited {
    @apply text-primary-contrast;
  }
}

a.btn-secondary {
  @apply text-primary;
  &:visited {
    @apply text-primary;
  }
}
