@mixin button-default() {
  @apply relative inline-flex cursor-pointer touch-manipulation items-center justify-center rounded-md border border-solid border-transparent px-4 py-3 text-base font-semibold;
}

@mixin disabled() {
  @apply pointer-events-none opacity-50;
}

@mixin input-default {
  @apply focus:border-primary focus:ring-primary box-border h-11 appearance-none rounded-md border border-gray-300 bg-white bg-none pl-3 pr-3 text-base shadow-sm outline-none placeholder:text-base placeholder:text-gray-400 hover:bg-gray-50 focus:bg-white;
}
